@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inria+Serif&display=swap");
@import url("https://fonts.googleapis.com/css?family=Inconsolata");

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  body,
  * {
    @apply font-inter;
  }
  :root {
    --primary: #ffb45f;
    --secondary: rgba(0, 0, 0, 0.50);
  }
  
  /* * {
    scrollbar-width: thin;
    scrollbar-color: var(--secondary) var(--primary);
    scroll-behavior: smooth;

  }
  

  *::-webkit-scrollbar {
    width: 15px;
  }
  
  *::-webkit-scrollbar-track {
    background: var(--primary);
    border-radius: 3px;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-radius: 3px;
    border: 3px solid var(--primary);
  } */
}

@layer components {
  .loadingPersent {
    @apply font-inter lg:text-[50px] text-2xl;
    font-style: normal;
    font-weight: 400;

    line-height: 39px;
    /* identical to box height */

    background: linear-gradient(0deg, #fff 100%, rgba(255, 255, 255, 4) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-shadow: 0px 4px 4px #000000;
  }
  .swiper-button-prev {
    @apply !bg-mainColor absolute top-1/2 right-5 w-12 aspect-square flex justify-center items-center  rounded-full;
    z-index: 300;
  }
  .swiper-button-next {
    @apply !bg-mainColor absolute top-1/2 left-5 w-12 aspect-square flex justify-center items-center rounded-full;

    z-index: 300;
  }
  .swiper-button-next_aboutUs {
    @apply !bg-white absolute top-1/2 -left-1 w-12 h-20 aspect-square flex justify-center items-center rounded-full;
    border-radius: 12rem 0 0 12rem;
  }
  .imageShadow {
    filter: drop-shadow(-8px 8px 23px rgba(0, 0, 0, 0.51));
  }
}
